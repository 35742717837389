var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ConflictingBookingRulesModal",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.medium,
          title: "Booking rules conflicts",
          "data-test-id": "booking-rules-conflicts-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("p", { staticClass: "pb-2" }, [
                  _vm._v(" Some vehicles belong to fleet segments with "),
                  _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                    _vm._v(" conflicting booking rules values. "),
                  ]),
                ]),
                _c(
                  "p",
                  { staticClass: "d-flex align-items-center pb-2" },
                  [
                    _vm._v(
                      " Please review the fleet segment in order to continue. "
                    ),
                    _c(
                      "ui-tooltip",
                      {
                        attrs: {
                          tooltip: _vm.tooltipText,
                          placement: _vm.PLACEMENTS.bottom,
                          "data-test-id": "info-tooltip",
                        },
                      },
                      [
                        _c("ui-icon", {
                          staticClass:
                            "emobg-color-ink-light emobg-color-ink-hover",
                          attrs: {
                            icon: _vm.ICONS.infoFull,
                            size: _vm.ICONS_SIZES.small,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.conflicts, function (conflict, index) {
                  return _c(
                    "div",
                    {
                      key: conflict.uuid,
                      class: [
                        "pt-3",
                        {
                          "emobg-border-bottom-1 emobg-border-color-ground-light":
                            index < _vm.size(_vm.conflicts) - 1,
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pb-1" },
                        [
                          _c(
                            "span",
                            { staticClass: "emobg-font-weight-semibold" },
                            [_vm._v("Vehicle: ")]
                          ),
                          _c(
                            "PermissionLink",
                            {
                              staticClass: "emobg-font-weight-semibold",
                              attrs: {
                                "link-permissions": [
                                  _vm.CARSHARING_PERMISSIONS
                                    .viewCarsharingVehicles,
                                ],
                                to: {
                                  name: _vm.fleet.vehicles.detail.details,
                                  params: {
                                    vehicleUuid: conflict.uuid,
                                  },
                                },
                                "data-test-id": `vehicle-link_${conflict.uuid}`,
                              },
                            },
                            [_vm._v(" " + _vm._s(conflict.licensePlate) + " ")]
                          ),
                        ],
                        1
                      ),
                      _vm._l(conflict.fleetSegments, function (fleetSegment) {
                        return _c(
                          "div",
                          { key: fleetSegment.uuid, staticClass: "pb-3" },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "span",
                                  { staticClass: "emobg-font-weight-semibold" },
                                  [_vm._v("Fleet segment: ")]
                                ),
                                _c(
                                  "PermissionLink",
                                  {
                                    staticClass: "emobg-font-weight-semibold",
                                    attrs: {
                                      "link-permissions": [
                                        _vm.CRM_PERMISSIONS.viewCrmCompanies,
                                      ],
                                      to: {
                                        name: _vm.crm.companies.detail
                                          .fleetSegments.edit,
                                        params: {
                                          fleetSegmentUuid: fleetSegment.uuid,
                                          companyUuid: fleetSegment.companyUuid,
                                        },
                                      },
                                      "data-test-id": `fleet-segment-link_${fleetSegment.uuid}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(fleetSegment.name) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.size(fleetSegment, "conflictingRules")
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      "data-test-id": `conflicting-rules-text_${fleetSegment.uuid}`,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "emobg-font-weight-semibold",
                                      },
                                      [_vm._v("Conflicting booking rules: ")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.conflictingRulesText(
                                            fleetSegment.conflictingRules
                                          )
                                        ) +
                                        ". "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c(
                      "ui-button",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          "data-test-id": "export_csv-button",
                          face: _vm.FACES.outline,
                        },
                        on: { clickbutton: _vm.exportCsvFile },
                      },
                      [_vm._v(" Export file ")]
                    ),
                    _c(
                      "ui-button",
                      {
                        attrs: { "data-test-id": "review_segments-button" },
                        on: { clickbutton: _vm.closeModal },
                      },
                      [_vm._v(" Review fleet segment ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }