<script>
import get from 'lodash/get';
import join from 'lodash/join';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import {
  mapMutations,
  mapState,
} from 'vuex';
import moment from 'moment-timezone';
import { camelCaseKeys, exportFile, sentenceCase } from '@emobg/web-utils';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import crm from '@domains/CRM/router/CRMRouterMap';
import { GenericModalComponent, PermissionLink } from '@/components';
import crmDomain from '@/domains/CRM/store/CRMModuleMap';

export default {
  name: 'ConflictingBookingRulesModal',
  components: {
    PermissionLink,
    GenericModalComponent,
  },
  computed: {
    ...mapState(crmDomain.fleetSegment, {
      conflicts: state => camelCaseKeys(get(state, 'fleetSegment.error.data.vehicles', [])),
    }),
  },
  created() {
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.fleet = fleet;
    this.crm = crm;
    this.tooltipText = 'Vehicles are not allowed to be in more than one fleet segment with different booking rules values.';
  },
  methods: {
    size,
    ...mapMutations(crmDomain.fleetSegment, [
      'resetError',
    ]),
    closeModal() {
      this.resetError();
      this.$emit('modal-closed');
    },
    conflictingRulesText(rules) {
      const rulesTextArray = map(rules, rule => {
        const ruleTranslated = this.$t(`CRM.Companies.FleetSegments.bookingRules.rules.${rule.key}.title`);
        const type = rule.type ? ` (${sentenceCase(rule.type)})` : '';
        return `${ruleTranslated}${type}`;
      });

      return join(rulesTextArray, ', ');
    },
    exportCsvFile() {
      const iteration = (csvAccumulator, conflict) => {
        const fleetSegmentCsvString = reduce(conflict.fleetSegments, (fleetSegmentAccumulator, fleetSegment) => {
          const conflictingRules = map(fleetSegment.conflictingRules, conflictingRule => {
            const rule = this.$t(`CRM.Companies.FleetSegments.bookingRules.rules.${conflictingRule.key}.title`);
            const type = conflictingRule.type ? ` (${sentenceCase(conflictingRule.type)})` : '';
            return `${rule}${type}`;
          });
          const conflictingRulesString = join(conflictingRules, ', ');
          return `${fleetSegmentAccumulator}\n${conflict.licensePlate},${fleetSegment.name},"${conflictingRulesString}"`;
        }, '');
        return `${csvAccumulator}${fleetSegmentCsvString}`;
      };
      const content = reduce(this.conflicts, iteration, 'Vehicle,Fleet Segments,Booking Rules');
      exportFile(content, `conflicting_booking_rules_${moment().format('YYYY-mm-DD_HH[h]mm[m]ss[s]')}`);
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.medium"
    title="Booking rules conflicts"
    data-test-id="booking-rules-conflicts-modal"
    class="ConflictingBookingRulesModal"
    v-on="$listeners"
  >
    <template #body>
      <p class="pb-2">
        Some vehicles belong to fleet segments with
        <span class="emobg-font-weight-semibold"> conflicting booking rules values. </span>
      </p>
      <p class="d-flex align-items-center pb-2">
        Please review the fleet segment in order to continue.
        <ui-tooltip
          :tooltip="tooltipText"
          :placement="PLACEMENTS.bottom"
          data-test-id="info-tooltip"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            :size="ICONS_SIZES.small"
            class="emobg-color-ink-light emobg-color-ink-hover"
          />
        </ui-tooltip>
      </p>
      <div
        v-for="(conflict, index) in conflicts"
        :key="conflict.uuid"
        :class="['pt-3', {
          'emobg-border-bottom-1 emobg-border-color-ground-light': index < (size(conflicts) - 1)
        }]"
      >
        <div class="pb-1">
          <span class="emobg-font-weight-semibold">Vehicle: </span>
          <PermissionLink
            :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
            :to="{
              name: fleet.vehicles.detail.details,
              params: {
                vehicleUuid: conflict.uuid,
              },
            }"
            :data-test-id="`vehicle-link_${conflict.uuid}`"
            class="emobg-font-weight-semibold"
          >
            {{ conflict.licensePlate }}
          </PermissionLink>
        </div>
        <div
          v-for="fleetSegment in conflict.fleetSegments"
          :key="fleetSegment.uuid"
          class="pb-3"
        >
          <div>
            <span class="emobg-font-weight-semibold">Fleet segment: </span>
            <PermissionLink
              :link-permissions="[CRM_PERMISSIONS.viewCrmCompanies]"
              :to="{
                name: crm.companies.detail.fleetSegments.edit,
                params: {
                  fleetSegmentUuid: fleetSegment.uuid,
                  companyUuid: fleetSegment.companyUuid,
                },
              }"
              :data-test-id="`fleet-segment-link_${fleetSegment.uuid}`"
              class="emobg-font-weight-semibold"
            >
              {{ fleetSegment.name }}
            </PermissionLink>
          </div>
          <div
            v-if="size(fleetSegment, 'conflictingRules')"
            :data-test-id="`conflicting-rules-text_${fleetSegment.uuid}`"
          >
            <span class="emobg-font-weight-semibold">Conflicting booking rules: </span>
            {{ conflictingRulesText(fleetSegment.conflictingRules) }}.
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <ui-button
          data-test-id="export_csv-button"
          :face="FACES.outline"
          class="mr-2"
          @clickbutton="exportCsvFile"
        >
          Export file
        </ui-button>
        <ui-button
          data-test-id="review_segments-button"
          @clickbutton="closeModal"
        >
          Review fleet segment
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
  .ConflictingBookingRulesModal {
    line-height: 18px;
  }
</style>
